import React from "react"
import Page from "./Page"
import Section from "./Section"

function Submodule(props) {
  let {submodule, submoduleIndex, moduleIndex} = props;
  let sections = submodule.sections || [];

  let submoduleOverview = "";
  if (submodule.submoduleOverview) {
    submoduleOverview = (
      <Page page={submodule.submoduleOverview} style={{marginBottom: "0.5em"}}/> 
    )
  }

  let detailsStyle = {
    background: "white",
    boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.3)"
  }

  let summaryStyle = {
    color: "#1D3868"
  }
  return (
    <details style={detailsStyle}>
      <summary style={summaryStyle}>{moduleIndex + 1}.{submoduleIndex + 1} - {submodule.name}</summary>
      { submoduleOverview }
      <div>
        {
          sections.map((value, index) => {
            return (
              <Section section={value} key={index}/>
            )
          })
        }
      </div>
    </details>
  )
}

export default Submodule