import React from "react"
import Page from "./Page"

function Section(props) {
  let {section} = props;
  let pages = section.pages || [];
  let sectionStyle = {
    borderLeft: "1em solid #D4EBE5",
    paddingLeft: "1em"
  }
  return (
    <div style={sectionStyle}>
      <h4>{section.name}</h4>
      <ul style={{listStyle: "none"}}>
      {
          pages.map((page, index) => {
            return (
              <li>
                <Page page={page} key={index}/>
              </li> 
            )
          })
        }
      </ul>
    </div>
  )
}

export default Section