import React from "react"
import Submodule from "./Submodule"
import Page from "./Page"

function Module(props) {
  let { module, moduleIndex } = props;
  let submodules = module.submodules || [];
  console.log(JSON.stringify(module));

  let moduleOverview = "";
  if (module.moduleOverview) {
    moduleOverview = (
      <div style={{marginBottom: "0.5em"}}>
        <Page page={module.moduleOverview}/>
      </div>
    )
  }
  let moduleStyle = {
    borderLeft: "1em solid #95CEBE",
    paddingLeft: "1em"
  }

  return (
    <div className="module" style={moduleStyle}>
      <h3>{moduleIndex + 1} - {module.name}</h3>
      { moduleOverview }
      <h4>Submodules:</h4>
      {
        submodules.map((value, index) => {
          return (
            <Submodule submodule={value} submoduleIndex={index} moduleIndex={moduleIndex} key={index}/>
          )
        })
      }
    </div>
  )
}

export default Module;